.pagination {
    display: flex;
    @include list-unstyled();
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
    }
}

.page-link {
    position: relative;
    display: block;
    color: $pagination-color;
    text-decoration: if($link-decoration == none, null, none);
    @include transition($pagination-transition);
    border-radius: calc(10rem / 16);
    font-size: calc(11rem / 16);
    padding: calc(12rem / 16) calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        padding: calc(10rem / 16) calc(18rem / 16);
    }

    &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }

    &:focus {
        z-index: 3;
        color: $pagination-focus-color;
        background-color: $pagination-focus-bg;
        outline: $pagination-focus-outline;
        box-shadow: $pagination-focus-box-shadow;
    }
}

.page-item {
    &:not(:first-child) .page-link {
        margin-left: $pagination-margin-start;
    }

    &.active .page-link {
        z-index: 3;
        color: $pagination-active-color;
        @include gradient-bg($pagination-active-bg);
        border-color: $pagination-active-border-color;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}

.page-item--arrow .page-link {
    background: none;
    padding: 0;
    font-size: calc(11rem / 16);
    letter-spacing: calc(2.5rem / 16);
}
.page-item__icon {
    font-size: calc(9rem / 16);
}

.pagination-info {
    font-size: calc(10rem / 16);
    letter-spacing: calc(1.5rem / 16);
}


//
// Sizing
//
//@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-border-radius);

//.pagination-lg {
//    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $pagination-border-radius-lg);
//}
//
//.pagination-sm {
//    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $pagination-border-radius-sm);
//}