// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: $nav-link-padding-y $nav-link-padding-x;
    @include font-size($nav-link-font-size);
    font-weight: $nav-link-font-weight;
    color: $nav-link-color;
    text-decoration: if($link-decoration == none, null, none);
    @include transition($nav-link-transition);

    &:hover,
    &:focus {
        color: $nav-link-hover-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    // Disabled state lightens text
    &.disabled {
        color: $nav-link-disabled-color;
        pointer-events: none;
        cursor: default;
    }
}

//
// Tabs
//

.nav-tabs {
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

    .nav-item + .nav-item {
        margin-left: calc(3rem / 16);
    }
    .nav-link {
        margin-bottom: -$nav-tabs-border-width;
        background: $secondary;
        color: $primary-light;
        border: $nav-tabs-border-width solid transparent;
        @include border-top-radius($nav-tabs-border-radius);
        letter-spacing: calc(2.2rem / 16);
        text-transform: uppercase;
        font-size: calc(10rem / 16);
        padding: calc(16rem / 16) calc(20rem / 16);
        transition: none;

        @media screen and (min-width: 768px) {
            font-size: calc(11rem / 16);
            padding: calc(20rem / 16) calc(30rem / 16);
            letter-spacing: calc(2.5rem / 16);
        }

        &:hover,
        &:focus {
            border-color: $nav-tabs-link-hover-border-color;
            // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
            isolation: isolate;
        }

        &.disabled {
            color: $nav-link-disabled-color;
            background-color: transparent;
            border-color: transparent;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        background-color: $nav-tabs-link-active-bg;
        border-color: $nav-tabs-link-active-border-color;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        position: relative;
        z-index: 1;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: calc(-21rem/16);
            bottom: 0;
            background: transparent;
            height: calc(34rem / 16);
            width: calc(22rem / 16);
            border-top-left-radius: calc(22rem / 16);
            box-shadow: 0 calc(-14rem/16) 0 0 $white;
            transform: rotate(-180deg);
        }

        &:after {
            left: auto;
            right: calc(-21rem / 16);
            border-top-left-radius: 0;
            border-top-right-radius: calc(22rem / 16);
        }
    }

    .dropdown-menu {
        // Make dropdown border overlap tab border
        margin-top: -$nav-tabs-border-width;
        // Remove the top rounded corners here since there is a hard edge above the menu
        @include border-top-radius(0);
    }

    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem / 16);
        margin-bottom: calc(-3rem / 16);

        .nav-item {
            flex-shrink: 0;
        }
    }
}


//
// Pills
//

.nav-pills {
    .nav-link {
        background: none;
        border: 0;
        @include border-radius($nav-pills-border-radius);
    }

    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        @include gradient-bg($nav-pills-link-active-bg);
    }
}


//
// Justified variants
//

.nav-fill {
    > .nav-link,
    .nav-item {
        flex: 1 1 auto;
        text-align: center;
    }
}

.nav-justified {
    > .nav-link,
    .nav-item {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }
}

.nav-fill,
.nav-justified {
    .nav-item .nav-link {
        width: 100%; // Make sure button will grow
    }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
    background: $white;
    position: relative;
    padding: calc(22rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(60rem / 16);
        border-radius: calc(14rem / 16);
    }

    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
    }
}
