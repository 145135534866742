.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin: calc(14rem / 16) 0 calc(30rem / 16);
    position: relative;
    z-index: 1;
    padding-left: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(17rem / 16) 0;
    }
    @include font-size($breadcrumb-font-size);
    list-style: none;
    @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
    color: $primary-light;
    display: flex;
    align-items: center;
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding-x;

        &::before {
            content: var(--icon-arrow-down-light);
            font-family: iconfont;
            transform: rotate(-90deg);
            color: $breadcrumb-divider-color;
            position: relative;
            font-size: calc(7rem / 16);
            margin-right: calc(8rem / 16);
        }
    }

    &.active {
        color: $breadcrumb-active-color;
    }
}
